<template>
  <div class="wrapper">
    <div class="main-panel">

      <nav class="navbar navbar-expand-lg navbar-absolute"
       :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}">
        <div class="container-fluid">
          <div class="navbar-wrapper" style="display: block">
            <a class="navbar-brand" href="#">Cardinal</a>
          </div>
        </div>
      </nav>

      <div class="content public">
        <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-8 text-center">
                <h2 class="title text-danger">404 Not Found</h2>
                <h2 class="title">Oops! It seems that this page does not exist.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>
