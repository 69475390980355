import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'

import "@/assets/css/custom.css";

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

import store from './store';


import axios from 'axios';

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.config.url.includes('/auth/login')) return Promise.reject(error);
  if (error.response.status === 401) {
    if (store.state.logged_in) {
      store.commit('logout');
    }
    error.response.data = false;
  }
  return Promise.reject(error);
});


Vue.mixin({
  data() {
    return {
      api_url: 'https://api.cardinal.is/api',
      q: {}
    }
  },
  mounted() {
    try {
      this.q = this.$route.query;
    } catch {}
  }
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  i18n
});