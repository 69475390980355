<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        © {{year}} made with <i class="tim-icons icon-heart-2"></i> by KsaRedFx
        <div style="font-size: 0.625rem">
          Theme by 
          <a href="https://www.binarcode.com" target="_blank" rel="noopener">Binar Code</a>&nbsp;&
          <a href="https://www.creative-tim.com" target="_blank" rel="noopener">Creative Tim</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
