import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    u_id: false,
    logged_in: false,
    last_search: {},
    modal_data: false,
    url: false
  },
  mutations: {
    login(state, uuid) {
      state.logged_in = true;
      state.u_id = uuid;
    },
    logout(state) {
      state.logged_in = false;
    },
    url(state, url) {
      state.url = url;
    },
    setSearch(state, term) {
      state.last_search = {...state.last_search, ...term}
    },
    setModal(state, data) {
      state.modal_data = data;
    }
  }
});

export default store;