import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import PublicLayout from "@/pages/Layout/PublicLayout.vue";
// GeneralViews
import NotFound from "@/pages/Error/404.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Dashboard.vue");

const Inventory = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Inventory.vue");
const Products = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Products.vue");

const Warehouses = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Warehouses.vue");

const Transactions = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Transactions.vue");
const Sales = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Sales.vue");

const Vendors = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Vendors.vue");
const VendorDetail = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dash/Detail/Vendor.vue");




// Auth pages
const Login = () => import(/* webpackChunkName: "public" */"@/pages/Auth/Login.vue");


// Public pages
const Purchase = () => import(/* webpackChunkName: "public" */"@/pages/Purchase.vue"); 


const routes = [
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {title: 'Cardinal - Dashboard'}
      },
      {
        path: "inventory",
        name: "inventory",
        component: Inventory,
        meta: {title: 'Cardinal - Inventory'}
      },
      {
        path: "products",
        name: "products",
        component: Products,
        meta: {title: 'Cardinal - Products'}
      },
      {
        path: "warehouses",
        name: "warehouses",
        component: Warehouses,
        meta: {title: 'Cardinal - Warehouses'}
      },
      {
        path: "sales",
        name: "sales",
        component: Sales,
        meta: {title: 'Cardinal - Sales'}
      },
      {
        path: "transactions",
        name: "transactions",
        component: Transactions,
        meta: {title: 'Cardinal - Transactions'}
      },
      {
        path: "vendors",
        name: "vendors",
        component: Vendors,
        meta: {title: 'Cardinal - Vendors'}
      },
      {
        path: "vendors/:id",
        name: "vendor_detail",
        component: VendorDetail,
        meta: {title: 'Cardinal - Vendor Detail'}
      }
    ]
  },
  {
    path: "/",
    component: PublicLayout,
    children: [
      {
        path: "purchase/:id",
        name: "purchase",
        component: Purchase,
        meta: {title: 'Cardinal - Delivery Info'}
      },
      {
        path: "login",
        name: "login",
        component: Login,
        meta: {title: 'Cardinal - Login'}
      } 
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
